import { useNavigate } from "react-router-dom";
import { Navigation, NavigationWrapper, NavItem } from "./styles"

// import { GoHome } from 'react-icons/go';
import { AiOutlineCalendar } from 'react-icons/ai';
import { AiFillCalendar } from 'react-icons/ai';

import { GiMushroomGills, GiSuperMushroom } from 'react-icons/gi';
import { FaUserCircle, FaRegUserCircle } from 'react-icons/fa';

import { IoTicketOutline } from 'react-icons/io5';
import { IoTicket } from 'react-icons/io5';

import { RiUserSmileFill } from 'react-icons/ri'
import { RiUserSmileLine } from 'react-icons/ri'

const Nav = (props) => {
    const navigate = useNavigate();

    let { pathName } = props

    return (
        <NavigationWrapper>
            <svg width="0" height="0">
            <linearGradient id="gradient" x1="2%" y1="0%" x2="98%" y2="100%" >
                <stop offset="0%" stopColor="rgb(211, 135, 171)" stopOpacity="1.00" />
                {/* <stop offset="50%" stopColor="rgb(3,132,101)" stopOpacity="1.00" /> */}
                <stop offset="100%" stopColor="rgb(232, 153, 220)" stopOpacity="1.00" />
            </linearGradient>
            </svg>
            <Navigation>
                <NavItem onClick={() => navigate("/events", { replace: true })}>
                        {
                        pathName.startsWith('/events') ?
                        <>
                        <AiFillCalendar style={{ fill: "url(#gradient)" }}/>
                        <span style={{"fontSize": "0.5em", "fontWeight": "bold"}}>Eventos</span>
                        </>
                        :
                        <>
                        <AiOutlineCalendar style={{ fill: "gray" }}/>
                        <span style={{"fontSize": "0.5em"}}>Eventos</span>
                        </>
                        }
                </NavItem>
                <NavItem onClick={() => navigate("/wallet", { replace: true })}>
                        {
                        pathName.startsWith('/wallet') ?
                        <>
                        <IoTicket style={{ fill: "url(#gradient)" }}/>
                        <span style={{"fontSize": "0.5em", "fontWeight": "bold"}}>Ingressos</span>
                        </>
                        :
                        <>
                        <IoTicketOutline stroke="gray"/>
                        <span style={{"fontSize": "0.5em"}}>Ingressos</span>
                        </>
                        }
                </NavItem>
                <NavItem onClick={() => navigate("/user", { replace: true })}>
                    {
                        pathName.startsWith('/user') ?
                        <>
                        <FaUserCircle style={{ fill: "url(#gradient)" }}/>
                        <span style={{"fontSize": "0.5em", "fontWeight": "bold"}}>Perfil</span>
                        </>
                        :
                        <>
                        <FaRegUserCircle style={{ fill: "gray" }}/>
                        <span style={{"fontSize": "0.5em"}}>Perfil</span>
                        </>
                        }
                </NavItem>
            </Navigation>
        </NavigationWrapper>
    )
}

export default Nav;